import React from "react";
import "./Noida.css";
import { Accordion } from "react-bootstrap";
import ImageGrid from "./Nc.js";

const NFC = () => {
  return (
    <>
      <section id="gk2">
        <div className="noida">
          <div className="noida1">
            <h4>
              Best Pilates Studio in GREATER KAILASH II
              <hr className="noida11" />
            </h4>
            <div className="noida2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3504.2686335895482!2d77.267547!3d28.561695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMzJzQyLjEiTiA3N8KwMTYnMDMuMiJF!5e0!3m2!1sen!2sin!4v1739483219628!5m2!1sen!2sin"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="noida3">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3>Greater Kailash II</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      M-510, Ground Floor, Greater Kailash 2, New Delhi 110048
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="noida23">
              <h4>
                Studio Gallery
                <hr className="noida11" />
              </h4>
              <ImageGrid />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NFC;
