import React from 'react'
import Imgh from '../Images/woman-with-pilates-trainer-practising-pilates.jpg'
import './PilatesTeachersTraining.css'
import Whatsapp from '../Components/Whatsapp';
import TurnYC from './TurnYC'
import FaqWidget from './FaqWidget';
import Navbar from '../Components/Navbar';
import Headroom from 'react-headroom';




const PilatesTeachersTraining = () => {


  return (
    <>
      <FaqWidget/>

      <Whatsapp />
      <div className='Hero2'>
        <img src={Imgh} alt='xxx' className='hero-image' />
        <Headroom>
          <div className='sticky-navbar'>
            <Navbar />
          </div>
        </Headroom>
      </div>   
      <div className='overlayxy'>
          <div className='content-container'>
            <p> <span className='writte1' style={{ color: '#D7BD92'}}>Pilates Teachers Training Program</span>  </p>
          </div>
        </div>
      <TurnYC/>
    </>
  )
}

export default PilatesTeachersTraining