import React from "react";
import "./Mainpage5.css";
import Img09 from "../Images/image 5.png";

const Mainpage5 = () => {
  const openGoogleForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link",
      "_blank"
    );
  };

  return (
    <>
      <div className="lain5">
        <div className="image-containerq">
          <img className="popopo" src={Img09} alt="Description of the" />
          <div className="text-boxq">
            <p className="main5o">
              Meet us at <br />
              Greater Kailash I | Green Park | New Friends Colony | Greater
              Kailash II | Noida
            </p>
            <p className="main5ooo">Start Pilates Today</p>
            <div className="mainoo">
              <p>
                Book a 60-minutes Introductory Class to walk through Reformer
                Pilates basics and familiarize yourself with the specialized
                studio equipment. All fitness levels are welcome.
              </p>
            </div>
            <div className="button-containerq">
              <button onClick={openGoogleForm}>Book a Trial Now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mainpage5;
