import React from "react";
import "./TurnYC.css";
import { useState } from "react";

const TurnYC = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: " ",
    email: " ",
    phone: " ",
    message: " ",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const { fname, lname, email, phone, message } = formData;
    const mailtoLink = `mailto:abc@gmail.com?subject=Form Submission&body=Name: ${fname}%20${lname}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="tyc1">
        <div className="tyc2">
          <p className="tyc3"> Turn your passion into your career!</p>
          <p className="tyc4" style={{ fontSize: "21px" }}>
            A 450-hour Comprehensive Pilates Certification Program
          </p>
        </div>
        <div className="tyc5">
          <p className="tyc6">WHAT IT IS?</p>
          <div className="tyc7">
            <p className="tyc4">
              Corefit Plus Pilates offers a 450-hour teacher training program
              covering all Pilates apparatus: Mat, Reformer, Jumpboard,
              Cadillac, Chair, Ladder Barrel, Spine Corrector, and Magic Circle.
              The program blends in-person training, independent study, and
              online learning to give you a complete Pilates education.
            </p>
          </div>
        </div>
      </div>
      <div className="tyc5">
        <p className="tyc6">WHY GET CERTIFIED?</p>
        <div className="tyc7">
          <p className="tyc4">
            Becoming a certified Pilates instructor opens doors to endless
            possibilities. Whether you&#39;re building a full-time career,
            pursuing a flexible side hustle, or deepening your personal
            practice, our program empowers you with the skills to succeed and
            uncover your body&#39;s full potential.
          </p>
        </div>
      </div>
      <div className="tyc5">
        <p className="tyc6">WHAT MAKES COREFIT PLUS DIFFERENT?</p>
        <div className="tyc7">
          <div className="tyc9">
            <ul>
              <li>
                Global Industry Standards – Meets requirements for top Pilates
                certifications
              </li>
              <li>
                Real-World Training – Learn through lectures, hands-on practice,
                observation, and teaching
              </li>
              <li>
                Mentorship for Life – Ongoing support even after certification
              </li>
              <li>
                Studio Access – Dedicated hours for self-practice and teacher
                training
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="tyc5">
        <p className="tyc6">WHAT YOU’LL LEARN</p>
        <p className="tyc4">
          Divided into 6 modules—History &amp; Anatomy, Mat, Reformer, Cadillac,
          Chair, and Barrel/Spine Corrector—you’ll learn:
        </p>
        <div className="tyc7">
          <div className="tyc9">
            <ul>
              <li>Pilates principles &amp; techniques across all equipment</li>
              <li>Class programming &amp; modifications for all levels</li>
              <li>
                How to work with props (magic circle, weights, balls, etc.)
              </li>
              <li>
                Anatomy &amp; movement principles (planes of motion,
                concentric/eccentric movements)
              </li>
              <li>
                Teaching methods—group classes, one-on-one sessions, and special
                populations
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="tyc5">
        <p className="tyc6">HOW DOES THE TRAINING WORK?</p>
        <div className="tyc7">
          <div className="tyc9">
            <ul>
              <li>
                6 modules covered over 1 month of classroom: In studio we will
                cover Anatomy, History of Pilates, Scope of Practice, and a
                breakdown of each exercise according to choreography, muscle
                focus, and contraindications. We will also spend time learning
                how to put together private training sessions, and group
                classes.
              </li>
              <li>
                Studio access for self practice and teacher training hours: Each
                program will require you to spend a set amount of hours
                practicing in class, teaching yourself through your manuals, or
                practicing from approved online resources.
              </li>
              <li>
                Mentorship throughout the program and beyond: Our commitment to
                your success doesn&#39;t end when you graduate. As a Corefit
                Plus Pilates certified instructor, you&#39;ll receive ongoing
                mentorship and support to help you thrive in your teaching
                journey.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="tyc5">
        <p className="tyc6">WHAT’S NEXT?</p>
        <p className="tyc4">
          Upon completion, you’ll be ready to sit for the National Pilates
          Certification Program (NPCP) exam and teach in:
        </p>
        <div className="tyc7">
          <div className="tyc9">
            <ul>
              <li>Big gyms &amp; boutique studios worldwide</li>
              <li>Private &amp; small group settings</li>
              <li>Corefit Plus Pilates studios</li>
            </ul>
          </div>
        </div>
        <p className="tyc4">
          Start your journey towards becoming a certified Pilates Instructor
          today!
        </p>
      </div>
      <div className="cun2">
        <div className="tycc12">
          <h4 style={{ fontFamily: "'Demibolds',sans-serif", color: "black" }}>
            CONTACT FORM <hr className="cu9" />
          </h4>
          <div className="tycc13">
            <form onSubmit={handleSubmit}>
              <div className="tycc14">
                <div>
                  <input
                    onChange={handleChange}
                    value={FormData.fname}
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <input
                    onChange={handleChange}
                    value={FormData.lname}
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                </div>
                <div>
                  <input
                    onChange={handleChange}
                    value={FormData.email}
                    type="text"
                    name="email"
                    placeholder="Email*"
                  />
                  <input
                    onChange={handleChange}
                    value={FormData.phone}
                    type="number"
                    name="phone"
                    placeholder="Phone*"
                  />
                </div>
              </div>
              <textarea
                onChange={handleChange}
                value={FormData.message}
                type="text"
                name="message"
                rows="7"
                placeholder="Type your comment*"
              ></textarea>
              <br />
              <br />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TurnYC;
