import React from "react";
import { useState } from "react";
import "./Popup1.css";

const Popup1 = () => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const closeModal = () => {
    setOverlayVisible(false);
    document.body.classList.remove("body-no-scroll");
  };

  return (
    <div className="Pop">
      {isOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content">
            <h3>CHAIR BALANCE REFORMER</h3>
            <div className="nbn">
              <p>Focus On Core And Balance</p>
              <p>
                {" "}
                Intensity:
                <span style={{ color: "#6A6665", fontWeight: "600" }}>
                  {" "}
                  OPEN TO ALL LEVELS
                </span>
              </p>
            </div>
            <div className="bnb">
              Great core stability workout routines performed on Wunda chairs to
              achieve maximum core strength, muscular endurance and balance.
              Added choreography with this challenging and complex Pilates'
              apparatus promises a high-intensity and heart-pumping workout.
              This workout requires a strong sense of mind-body connection and
              correct breathing technique to help gain maximum results.
            </div>
            <br />
            <p className="vbv">
              {" "}
              To make your booking, please call/sms to +91 9667744326,
              8595268566
            </p>

            <i
              className="fa fa-times fa*3x close-button "
              onClick={closeModal}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </div>
  );
};
export default Popup1;
