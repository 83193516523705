import React from 'react'
import FaqIcon from '../Images/faq.png'
import './FaqIcon.css'
import { Link } from 'react-router-dom'

const FaqWidget = () => {
  return (
    <>
    <div className='faqss'>
     <Link to="/faqs"><img src={FaqIcon} alt="FAQ" />
    </Link>
    </div>
    </>
  )
}

export default FaqWidget