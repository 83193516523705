import React from "react";
import "./WorkOut.css";
import Whatsapp from "../Components/Whatsapp";
import FaqWidget from "./FaqWidget";
import Navbar from "../Components/Navbar";
import MainImg from "../Images/Clasess.webp";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import Headroom from "react-headroom";
const WorkOut = () => {
  useEffect(() => {
    document.title = "Corefit Plus Pilates Studio | Pilates Class types ";
  }, []);

  const handle = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Workout types of Pilates | Corefit Plus Pilates Studio </title>
        <meta
          name="description"
          content="See the Perfect Pilates Exercise for Your Fitness Goals at CorefitPlus. Find the Perfect Class to Enhance Your Strength, Flexibility, and Well-Being. Join Us Today!"
        />
      </Helmet>

      <Whatsapp />
      <FaqWidget />

      <div className="Hero1">
        <img src={MainImg} alt="xxx" className="hero-image" />
        <Headroom>
          <div className="sticky-navbar">
            <Navbar />
          </div>
        </Headroom>
      </div>
      <div className="overlayxy">
        <div className="contentt-container">
          <p>
            {" "}
            <span className="writte1">Classes</span>{" "}
          </p>
        </div>
      </div>
      <div>
        <div className="toggle">
          <span>
            <Link className="link-pilates" onClick={() => handle("pilates")}>
              Pilates
            </Link>
          </span>{" "}
          /{" "}
          <span>
            <Link className="link-pilates" onClick={() => handle("yoga")}>
              Yoga
            </Link>
          </span>
        </div>
        <hr className="mnbv" />

        <section id="pilates">
          <div className="wo">
            <div className="Wo1">
              <div className="wo22">
                <p>PILATES</p>
                <hr className="mnbv" />
              </div>
              <div className="wo33">
                <p>
                  <div>CLASSES FOR EVERYBODY</div>
                  Explore our range of Pilates class types designed to cater to
                  your unique needs and preferences. Whether you're a beginner
                  looking to build a strong foundation or an advanced
                  practitioner seeking a challenging workout, we have the ideal
                  classes for you.
                  <p style={{ fontSize: "18px" }}>
                    We offer Group Sessions, Private Sessions, Private
                    Duo at our Studios.
                  </p>
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>BEGINNER CLASSICAL</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A foundational class focused on core strength, flexibility,
                  and alignment using traditional Pilates exercises. This
                  session incorporates both the Mat and Reformer, making it
                  perfect for those new to Pilates or looking to refine their
                  basics while experiencing the benefits of both formats.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>INTERMEDIATE/ADVANCED CLASSICAL</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A dynamic and challenging class following classical Pilates
                  sequences. Utilizing both the Mat and Reformer, this session
                  is perfect for those with experience who want to deepen their
                  practice with stronger core work, fluid transitions, and a
                  more advanced repertoire.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>CHAIR/TOWER CONTEMPORARY</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A full-body workout using the Pilates Chair and Tower to build
                  strength, balance, and control. This session focuses
                  on precision, stability, and resistance training, offering a
                  unique way to challenge your muscles. Perfect for those
                  looking to elevate their Pilates practice with varied
                  movements and dynamic flow.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>JUMPBOARD CONTEMPORARY</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A high-energy, cardio-infused Pilates class that
                  blends Jumpboard, rowing intervals, trampoline work, and other
                  dynamic movements to boost endurance, core stability, and
                  strength. This fast-paced session keeps you moving, sweating,
                  and engaged while adding an exciting twist to your Pilates
                  practice.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>STRETCH FLOW</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A gentle yet effective class designed to release tension,
                  improve flexibility, and enhance mobility through controlled
                  movements and deep stretches. This session focuses
                  on lengthening muscles, improving posture, and increasing
                  range of motion. This class is suitable for all levels and
                  leaves restored.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>POWER PROPS CONTEMPORARY</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A creative Pilates session designed to build strength,
                  stability, and coordination using TheraBand, Magic Circle,
                  BOSU, TRX, and more. This class adds variety and intensity to
                  your workout, helping you move with greater control,
                  endurance, and precision while keeping your routine engaging.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>SIGNATURE CONTEMPORARY</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  Our signature full-body Reformer workout designed to
                  build strength, stamina, and balance through dynamic and
                  controlled movements. This class focuses on core engagement,
                  muscle endurance, and fluid transitions, delivering a
                  challenging yet low-impact workout. With an emphasis
                  on precision and flow, this class is a perfect blend of
                  strength and stretch for all levels.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>MAT CONTEMPORARY</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A dynamic and energizing Mat-based Pilates class that
                  blends strength, mobility, and fluid movement for a full-body
                  workout. This session builds core stability, flexibility, and
                  control, incorporating modern variations and props
                  like TheraBand, small weights, or stability balls to challenge
                  your body in new ways. Perfect for those looking to refine
                  their technique, improve posture, and move with greater ease
                  and efficiency.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>YOGA FLOW</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A dynamic and grounding class designed to enhance flexibility,
                  strength, and mindfulness through a blend of movement and
                  breathwork. Suitable for all levels, this session
                  incorporates stretching, balance, and relaxation techniques to
                  help release tension, improve mobility, and restore energy.
                  This class offers a refreshing and holistic approach to
                  movement and well-being.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>RHYTHM &amp; MOVE; LATIN BEATS</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  A high-energy dance class that blends music, movement, and
                  fun to improve coordination, endurance, and full-body
                  strength. Designed for all fitness levels, this session
                  incorporates easy-to-follow choreography and dynamic
                  routines to get your heart pumping. This class is the perfect
                  way to sweat, groove, and feel amazing.
                  <br />
                </p>
              </div>
            </div>
            <div className="Wo1">
              <div className="wo2">
                <p>BOXBURN CARDIO/BLAST</p>
                <hr />
              </div>
              <div className="wo3">
                <p>
                  <br />
                  Join our BoxBurn classes for a fun mix of boxing techniques,
                  endurance building, and functional training. Perfect for all
                  skill levels, this class combines time-based circuits with
                  bodyweight moves, dumbbells, and kettlebells to challenge your
                  strength, stamina, and agility. Jab, cross, squat, and swing
                  your way to a fitter, stronger you! ß
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="yoga">
          <div className="we">
            <div className="part2">
              <div className="Wo1">
                <div className="uo22">
                  <p>Yoga</p>
                  <hr className="mnbv" />
                </div>
                <div className="wo33">
                  <p>A Better Life For All</p>
                  <p className="wo0">
                    This 21st century Yoga is built on western scientific
                    foundations combined with the wisdom of Ayurveda. In every
                    single session, experience better health and satisfaction as
                    you enjoy the benefits of a youthful, well-toned body to be
                    able to live life to your fullest.
                  </p>
                  <p style={{ fontSize: "19px" }}>
                    Yoga classes are available only at Noida Studio.
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>ENERGY & BALANCE</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    SUN VINYASA
                    <br />
                    <div className="wo0">
                      <p>
                        Awakening and balancing your solar power, this Solar
                        series is a great way to be fit and healthy and is
                        suitable for athletes, students, working professionals,
                        and seniors alike! This class benefits all systems of
                        the body i.e. cardiovascular, muscular, skeletal and
                        nervous systems.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>CORE STRENGTH</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    YOGA CORE
                    <br />
                    <div className="wo0">
                      <p>
                        These postures help reduce back pain, strengthen the
                        core muscles that support the back, hips, and stomach.
                        Having strong abdominal muscles and back muscles is the
                        key to prevent back pain. Strong core helps advancing
                        and deepening in various yoga postures and other
                        spiritual practices.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>ASANA BASED CARDIO</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    CARDIO YOGA
                    <br />
                    <div className="wo0">
                      <p>
                        Stop running or cycling and try our cardio yoga that
                        delivers maximum benefits from burning fats for 24 to 48
                        hrs after you complete your exercise along with toning
                        and firming your body that you usually can’t get from
                        running. There is no better exercise that incorporates
                        complete body training that works your core, upper body
                        and lower body strength versus just working your legs
                        alone. Strengthen your heart, burn fats and boost
                        metabolism in this amazing class.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: INTERMEDIATE LEVEL 
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>REJUVENATE</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    ASANA PRANA
                    <br />
                    <div className="wo0">
                      <p>
                        Use of specific postures and breathing techniques to
                        help relieve stress and tension instantly. This class
                        helps align the body, increase blood circulation,
                        improve the endocrine activity and correct body
                        metabolism along with physiological functions.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>DETOX & TONE</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    HOT DHARA
                    <br />
                    <div className="wo0">
                      <p>
                        Performed in Hot infra-red light and not traditional
                        heaters. A flowing series of 31 postures helps burn fats
                        fast along with making your body lean and toned. It
                        helps relieve health problems like migraine, back pain,
                        neck pain, numbness, respiratory issues, menstrual
                        issues and sleeping disorders.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: INTERMEDIATE LEVEL
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>POWER FLOW</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    YOGA SHAKTI
                    <br />
                    <div className="wo0">
                      <p>
                        This class covers all aspects of classical Yoga practice
                        in a systematic way. Specific Pranayama (breathing),
                        Asana (postures), Dharana (concentration), Bandha
                        (locks) and Nidra (blissful sleep) awaken dormant
                        powers. This systematic approach will give you the
                        foundation to reach greater heights as you achieve
                        better health both physically and mentally.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: INTERMEDIATE LEVEL
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>REVERSE AGING</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    ANTI-AGING YOGA
                    <br />
                    <div className="wo0">
                      <p>
                        This class raises your body temperature, compresses and
                        decompresses the body tissues to eliminate the toxins.
                        The poses help with lymphatic drainage, constipation
                        relief and removal of energy blocks. All the stretching,
                        twisting and inversions will help revitalize and reverse
                        aging.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>YOUTHFUL & PAIN</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    YOGA STRETCH
                    <br />
                    <div className="wo0">
                      <p>
                        It helps de-stress, relieve pain and stiffness, improves
                        elasticity of skin and muscles, improves range of
                        motion, releases blocked energy and boosts circulation.
                        In other words, it postpones aging while making you feel
                        light and refreshed in each session .
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: INTERMEDIATE LEVEL
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>HEAL BODY & MIND</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    YOGA CHIKITSA
                    <br />
                    <div className="wo0">
                      <p>
                        This is a therapeutic aspect of yoga, especially for
                        those with sports injuries and any health problems like
                        stress, high blood pressure, back and neck pain, asthma,
                        gastric issues, constipation and sleep disorders.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
              <div className="Wo1">
                <div className="uo2">
                  <p>BODY & MIND GROWTH</p>
                  <hr />
                </div>
                <div className="wo3">
                  <p>
                    YOGA FOR TWEENS
                    <br />
                    <div className="wo0">
                      <p>
                        Playful poses for the youth that work well on the body,
                        mind, and breath. Yoga helps youngsters with their rapid
                        growth, making the teen’s body strong and flexible,
                        improves clarity and focus, empowers their mind,
                        emotions and energy in a positive way.
                      </p>{" "}
                    </div>
                    <br />
                    Intensity: OPEN TO ALL LEVELS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WorkOut;
