import React from 'react'
import Img01 from '../Images/mam .jpg'
import Img02 from '../Images/Group 7.png'
import Whatsapp from '../Components/Whatsapp'
import FaqWidget from './FaqWidget'
import Wwb from '../Components/Wwb'
import { Link } from 'react-router-dom'
import './AboutUs.css'
import MainImg from '../Images/5.png'
import Navbar from '../Components/Navbar'
import Headroom from 'react-headroom';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';


const AboutUs = () => {

  useEffect(() => {
    document.title = 'About us | Corefit Plus Pilates Studio';
  }, []);


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }



  return (
    <>
      <Helmet>
        
        <title>About Corefit plus | Pilates Studio GK 1, Greenpark, Noida </title>
        <meta
          name="description"
          content="The corefit Plus studio started in 2018, Our Story, Our Mission, and Our Commitment to Your Wellness. Explore Our About Page to Learn More About Our Passion for Fitness and Balance."
        />

      </Helmet>



      <Whatsapp />
      <FaqWidget />
      <div className='Hero1'>
        <img src={MainImg} alt='xxx' className='hero-image' />
        <Headroom>
          <div className='sticky-navbar'>
            <Navbar />
          </div>
        </Headroom>
      </div>      <div className='Amain'>
        <div className='Amain2'>
          <p className='ap' style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>Corefit Plus</p>
          <p className='ap2'>A Wellness Destination</p>
        </div>
        <div className='overlayxy'>
          <div className='content-container'>
            <p> <span className='write1' style={{ color: '#D7BD92' }}>About Us</span>  </p>
          </div>
        </div>
        <div className='Amain3'>
          <p className='ap3'>Corefit Plus is a premier Pilates studio dedicated to helping individuals achieve optimal health, strength, and balance. Located in the heart of the city, our studio offers a welcoming and inclusive environment for all fitness levels, from beginners to advanced practitioners.</p>
        </div>
        <div className='Amain4'>
          <div className='Amain5'>
            <p className='ap4' style={{ fontFamily: "'Demibolds',sans-serif" }}>Classes</p>
            <p className='ap5'>
              Our classes are designed for all levels, from beginners to seasoned practitioners. Choose between group sessions for a motivating atmosphere or private sessions for personalized attention. Plus, our classes are fully customizable to cater to your specific goals and needs.
            </p>
            <Link onClick={scrollToTop} to='/classes'><button className='ap6'>View Classes</button></Link>
          </div>
          <div className='Amain5'>
            <p className='ap4' style={{ fontFamily: "'Demibolds',sans-serif" }}>Instructors</p>
            <p className='ap5'>
              Our instructors are deeply committed to your well-being, understanding that Pilates goes beyond physical fitness. They are passionate about nurturing not only your body but also your mind and spirit, creating a holistic and transformative experience at Corefit Plus Pilates Studio.            </p>
            <Link onClick={scrollToTop} to='/PilatesTeachersTraining'> <button className='ap6'>Learn More</button></Link>
          </div>
          <div className='Amain5'>
            <p className='ap4' style={{ fontFamily: "'Demibolds',sans-serif" }}>Studios</p>
            <p className='ap8' style={{ color: 'white' }}>We have designed our space and our programs to be so much more. At CoreFit Plus, we have redefined wellness as a 360-degree approach to health, recognizing that everyone’s journey is unique. Our studio isn’t just about losing weight, it’s about gaining strength, flexibility, confidence, and a community that supports you.</p>
            <Link onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} to='/ourstudio'><button className='ap6'>Know More</button></Link>
          </div>
        </div>

      </div>
      <div className='ap21'>
        <div className='ap22'>
          <p className='ap24' style={{ fontFamily: "'Demibolds',sans-serif" }}>Our Story</p>
          <p className='ap25'>
            Our  journey began with a vision—to introduce India to the  power of authentic Pilates. We believe that Pilates isn't a luxury reserved for a select few; it's a wellness practice accessible to all, including those on a path to rehabilitation. Inspired by my personal journey, we've proudly opened the  real Pilates to India, providing a nurturing space to help individuals achieve their health and fitness goals. We're dedicated to redefining wellness in India, always striving to do things differently.            </p>
          <Link onClick={scrollToTop} to='/Blog1'>
            <button className='ap26'>
              <img src={Img02} alt=" description" />
              View our Director’s Story
            </button></Link>
        </div>
        <div className='ap23'>
          <img src={Img01} alt='xxx' />
        </div>

      </div>

      <Wwb />
    </>
  )
}

export default AboutUs