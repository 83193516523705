import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Workout from "./Pages/WorkOut";
import OurStudio from "./Pages/OurStudio";
import ContactUs from "./Pages/ContactUs";
import PilatesTeachersTraining from "./Pages/PilatesTeachersTraining";
import Scroller from "./Components/Scroller";
import Footer from "./Pages/Footer";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import RefundPolicy from "./Components/RefundPolicy";
import Term from "./Components/Term";
import Faqs from "./Pages/Faqs";
import Noida from "./Components/Noida";
import GK from "./Components/GK";
import GP from "./Components/GP";
import GK2 from "./Components/GK2";
import NFC from "./Components/NFC";
import Blog1 from "./Pages/Blog1";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Classes" element={<Workout />} />
        <Route path="/OurStudio" element={<OurStudio />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route
          path="/PilatesTeachersTraining"
          element={<PilatesTeachersTraining />}
        />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/Term" element={<Term />} />
        <Route path="/scroller" element={<Scroller />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/Noida" element={<Noida />} />
        <Route path="/GK" element={<GK />} />
        <Route path="/GP" element={<GP />} />
        <Route path="/GK2" element={<GK2 />} />
        <Route path="/NFC" element={<NFC />} />
        <Route path="/Blog1" element={<Blog1 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
