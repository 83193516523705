import React, { useEffect } from "react";
import "./Scroller.css";
import { useState } from "react";

function extractAndSortTimeSlots(data) {
  let timeSlots = new Map(); // Using Map to ensure uniqueness

  data.forEach((entry) => {
    const date = new Date(entry.startDate);
    let classDuration = entry.class.classDuration; // Duration in minutes

    // Calculate start time in 24-hour format
    let startHours = date.getHours();
    let startMinutes = date.getMinutes();

    // Calculate end time
    let endDate = new Date(date.getTime() + classDuration * 60000);
    let endHours = endDate.getHours();
    let endMinutes = endDate.getMinutes();

    // Format start time in 12-hour AM/PM format
    let startAMPM = startHours >= 12 ? "PM" : "AM";
    startHours = startHours % 12 || 12;
    let formattedStartTime = `${startHours}:${startMinutes
      .toString()
      .padStart(2, "0")} ${startAMPM}`;

    // Format end time in 12-hour AM/PM format
    let endAMPM = endHours >= 12 ? "PM" : "AM";
    endHours = endHours % 12 || 12;
    let formattedEndTime = `${endHours}:${endMinutes
      .toString()
      .padStart(2, "0")} ${endAMPM}`;

    let timeRange = `${formattedStartTime} - ${formattedEndTime}`;

    // Store unique time slots using Map (prevents duplicates)
    if (!timeSlots.has(timeRange)) {
      timeSlots.set(timeRange, date);
    }
  });

  // Convert Map keys to array and sort by actual Date objects
  return [...timeSlots.entries()]
    .sort((a, b) => {
      const getHourValue = (str) => {
        const [hour, period] = str.split(" ")[0].split("-");
        return (parseInt(hour) % 12) + (str.includes("PM") ? 12 : 0);
      };
      return getHourValue(a[0]) - getHourValue(b[0]);
    })
    .map((slot) => slot[0]); // Extract only the formatted string
}

function transformSchedule(data) {
  let scheduleByTime = {}; // Stores schedule grouped by time slots

  data.forEach((entry) => {
    if (
      entry.class.className.includes("Private") ||
      entry.class.className.includes("PILATES PAUSE")
    ) {
      return;
    }

    const date = new Date(entry.startDate);
    let dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1; // Adjust Sunday to be last
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    let classDuration = entry.class.classDuration; // Duration in minutes

    hours = hours % 12 || 12; // Convert to 12-hour format
    let startTime = `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    // Calculate end time
    let endDate = new Date(date.getTime() + classDuration * 60000); // Add duration in milliseconds
    let endHours = endDate.getHours();
    let endMinutes = endDate.getMinutes();
    let endAmpm = endHours >= 12 ? "PM" : "AM";

    endHours = endHours % 12 || 12; // Convert to 12-hour format
    let endTime = `${endHours}:${endMinutes
      .toString()
      .padStart(2, "0")} ${endAmpm}`;

    let timeRange = `${startTime} - ${endTime}`;

    // Ensure time slot exists
    if (!scheduleByTime[timeRange]) {
      scheduleByTime[timeRange] = new Array(7).fill(0); // Initialize array for 7 days
    }

    // Assign class data to the respective day index (Monday = 0, Sunday = 6)
    let classData = {
      className: entry.class.className,
      trainer: entry.trainer.fullname,
      duration: `${entry.class.classDuration} mins`,
      spotsAvailable: entry.class.classSpots,
      isCancelled: entry.class.isCancelled,
      classDescription: entry.class.classDescription,
    };

    // Replace empty object with actual class data for that day
    // scheduleByTime[timeRange][dayIndex] = classData;
    if (scheduleByTime[timeRange][dayIndex]) {
      scheduleByTime[timeRange][dayIndex].push(classData);
    } else {
      scheduleByTime[timeRange][dayIndex] = [classData];
    }
  });

  // Sorting time slots in ascending order
  const sortedKeys = Object.keys(scheduleByTime).sort((a, b) => {
    const getHourValue = (str) => {
      const [hour, period] = str.split(" ")[0].split("-");
      return (parseInt(hour) % 12) + (str.includes("PM") ? 12 : 0);
    };
    return getHourValue(a) - getHourValue(b);
  });

  // Creating sorted object
  const sortedGroupedData = {};
  sortedKeys.forEach((key) => {
    sortedGroupedData[key] = scheduleByTime[key];
  });

  return sortedGroupedData;

  // return scheduleByTime;
}

const Scheduler = () => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [schedules, setSchedule] = useState([]);
  const [timeRangeArr, setTimeRange] = useState([]);
  const [locations, setLocations] = useState({});
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
    document.body.classList.toggle("body-no-scroll", setOverlayVisible);
  };

  const closeModal = () => {
    setOverlayVisible(false);
    document.body.classList.remove("body-no-scroll");
  };

  useEffect(() => {
    fetch("https://corefitplus-backend.el.r.appspot.com/get-locations")
      .then((response) => response.json())
      .then((data) => setLocations(data))
      .catch((error) => console.error("Error fetching locations:", error));
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(
      "https://corefitplus-backend.el.r.appspot.com/get-default-location-data"
    )
      .then((response) => response.json())
      .then((data) => {
        const timeRange = extractAndSortTimeSlots(data);
        setTimeRange(timeRange);
        const transformData = transformSchedule(data);
        setSchedule(transformData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
        setLoading(false);
      });
  }, []);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
    setLoading(true);
    fetch("https://corefitplus-backend.el.r.appspot.com/get-location-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ locationId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const timeRange = extractAndSortTimeSlots(data);
        setTimeRange(timeRange);
        const transformData = transformSchedule(data);
        setSchedule(transformData);
        setLoading(false);
      })
      .catch((error) => console.error("Error posting location:", error));
  };

  // Define the week day names
  const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  // Get today's date and determine the day index (0 = Sunday, 1 = Monday, etc.)
  const today = new Date();
  const currentDayIndex = today.getDay();

  // Compute Monday's date. If today is Sunday (0), we subtract 6 days.
  const monday = new Date(today);
  monday.setDate(
    today.getDate() - (currentDayIndex === 0 ? 6 : currentDayIndex - 1)
  );

  // Create an array that maps each day of the week with its corresponding date
  const weekDays = days.map((day, index) => {
    const date = new Date(monday);
    date.setDate(monday.getDate() + index);
    // Format the date (e.g., "Feb 23")
    const options = { month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return { day, formattedDate };
  });

  return (
    <>
      <section id="classes"></section>
      <div>
        <p className="head">
          Corefit Plus{" "}
          <span
            style={{ fontFamily: "BlueLB", fontSize: "30px", color: "#D7BD92" }}
          >
            Schedule
          </span>
        </p>{" "}
        <hr className="ere" />
      </div>
      <div className="mn3">
        <p>
          Our schedule is designed with your busy life in mind. With flexible
          class times and a diverse range of Pilates classes, you can find the
          perfect fit for your fitness journey. Discover the freedom to choose
          and create your ideal Pilates routine at Corefit Plus.
        </p>
      </div>
      <div className="select-container">
        <label htmlFor="location" className="dropdown-label">
          Location
        </label>
        <select
          id="location"
          value={selectedLocation}
          onChange={handleLocationChange}
          className="dropdown-select"
        >
          {locations.length > 0 ? (
            locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No locations available
            </option>
          )}
        </select>
      </div>
      {/* Schedule Content */}
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p>Loading schedule...</p>
        </div>
      ) : (
        <div id="schedule">
          <div className="container">
            <div className="heading-row">
              <div className="heading">
                <span className="heading-left"></span>
                <span className="heading-right"></span>
                <div className="heading-border-row">
                  <div className="heading-border"></div>
                </div>
              </div>
            </div>
            <div className="description-row">
              <div className="description"></div>
            </div>
            <div className="table-container">
              <div className="schedule-table">
                <div className="table-header">
                  <div className="head-block date-label">
                    DAY AND TIME<div className="week-day"></div>
                  </div>
                  {weekDays.map((item, idx) => (
                    <div className="head-block" key={idx}>
                      {item.day}
                      <div className="week-day">{item.formattedDate}</div>
                    </div>
                  ))}
                </div>
                <div className="table-content">
                  {timeRangeArr.map((timeRange, index) => {
                    const timeStart = timeRange.split(" - ")[0];
                    const timeEnd = timeRange.split(" - ")[1];
                    return (
                      <div
                        className={
                          index % 2
                            ? "even-row table-content-row"
                            : "odd-row table-content-row"
                        }
                        key={index}
                      >
                        <div className="square-block time-block">
                          <div className="text-div">
                            {timeStart}
                            <br />
                            to
                            <br />
                            {timeEnd}
                          </div>
                          <div className="book-div">BOOK NOW</div>
                        </div>
                        {schedules[timeRange]?.map((item, idx) => {
                          const cell = item || [];

                          return (
                            <div
                              className={
                                idx % 2
                                  ? "table-block square-block even-block"
                                  : "table-block square-block odd-block"
                              }
                            >
                              {cell?.map((classData, index) => {
                                return (
                                  <div
                                    style={{
                                      width: "150px",
                                      height: "108px",
                                      padding: "16px",
                                    }}
                                    className="table-block2"
                                    key={index}
                                  >
                                    <div className="text-div">
                                      {classData.className}
                                    </div>
                                    {classData.className && (
                                      <div
                                        className="book-div"
                                        onClick={() => {
                                          toggleOverlay();
                                          setSelectedClass(classData);
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginBottom: "0",
                                          }}
                                        >
                                          View Details
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOverlayVisible && selectedClass && (
        <div className="overlay-schedule" onClick={closeModal}>
          <div className="overlay-content-schedule">
            <h3 style={{ textTransform: "uppercase" }}>
              {selectedClass.className}
            </h3>
            <div className="bnb">{selectedClass.classDescription}</div>
            <br />
            <p className="vbv">
              To make your booking, please call/sms to +91 9667744326,
              8595268566
            </p>
            <i
              className="fa fa-times fa*3x close-button"
              onClick={closeModal}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </>
  );
};

export default Scheduler;
