import React from "react";
import { Accordion } from "react-bootstrap";
import ImageGrid from "./GPC.js";

const GP = () => {
  return (
    <>
      <section id="noida3">
        <div className="noida">
          <div className="noida1">
            <h4>
              Best Pilates Studio in GREEN PARK
              <hr className="noida11" />
            </h4>
            <div className="noida2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4851693870287!2d77.19911357522307!3d28.555190387589203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce30a7495a3e1%3A0xb20953d8f1844e4c!2sCorefit%20Plus%20Pilates%20Studio%20%7C%20Pilates%20Workout%20Training!5e0!3m2!1sen!2sin!4v1694001529139!5m2!1sen!2sin"
                title="GPMap"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>{" "}
            </div>
            <div className="noida3">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3>GREEN PARK</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      {" "}
                      K- 2, Near Greenpark Market, Green Park, New Delhi, Delhi
                      110016
                    </p>{" "}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="noida23">
              <h4>
                Studio Gallery
                <hr className="noida11" />
              </h4>
              <ImageGrid />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GP;
