import React from 'react'
import './Blog1.css'
import Img05 from '../Images/mam .jpg'
import Navbar from '../Components/Navbar'


const Blog1 = () => {
    return (
        <>
        <Navbar/>
            <div className='blo1'>
                <div className='blo2'>
                    <p className='blo5' style={{ fontFamily: "'Demibolds',sans-serif" }}>A Journey of Shobana Mahansaria, Women Entrepreneur & Director of Corefit Plus</p>
                    <p className='blo6'>When Shobana Mahansaria was diagnosed with ADEM (Acute Disseminated Encephalomyelitis), doctors told her chances of survival were slim. But against all odds, she rebuilt her body back from the basics. She learnt quickly that it’s her mind that controls the body.</p>
                    <div className='blo7'>
                        <div className='blo8'>
                           <p className='blo17'> “As scary as it was, I always tried to stay strong and positive, it can affect me wherever it wants. I can’t stop it, but it can’t take my attitude and it can’t take my faith. I was determined to stay strong and fight until I couldn’t do it anymore.”
                           </p>
                            <p className='blo16'>Shobana Mahansaria</p>
                        </div>
                        <div className='blo9'>
                            <img src={Img05} alt='xxx' />
                        </div>            </div>

                        <p className='blo10'>Pilates has played a very important role in my recovery, which is why she recommends it to anyone who is struggling with rehabilitation of any kind. She had no idea her journey would result in a new organization, Corefit Plus,– the venture she started whose mission is to aware and help people how Pilates can strengthen your core and motivate you for a healthy lifestyle.<br/>

                            What happens when you’re suddenly told one day that you will never be able to walk again? In some cases, it can completely crush one’s self-esteem and motivation to live, but not in my case. Having an illness such as ADEM (Acute Disseminated Encephalomyelitis) can be life-changing for anyone, as it was for me in many ways. But, to be able to rebuild my body back from the basics, to walking with a stick at the age of 35, to constantly be told that I should feel happy with my condition, just because I was better off than many, was a constant struggle for me which was not only physical but mental as well. But I learnt quickly that it’s my mind that controls my body. I have always felt that no matter how we feel on the inside, it is very important to get dressed and show up for recovery. Pilates has played a very important role in my recovery, which is why I would recommend it to anyone who is struggling with the rehabilitation of any kind.<br/>

                            Pilates is a systemised form of exercise, which focuses on core strengthening, which includes our abdominal and our back muscles. Pilates is now widely practised as an injury rehabilitation method on a global scale to restore imbalances in injured muscles. By evaluating a person’s state, strength, targets, and range of movement, a pilates personal training approach can be designed to facilitate injury rehabilitation of your muscles. It is a great tool to assist or even enhance a physiotherapy program when someone is recovering from an injury. By strengthening the deepest muscles of the core, optimising alignment, and creating correct movement patterns, we can also help to prevent the reaggravation of those injuries and the development of new ones. So, it is safe to say that it is a viable and effective method of movement re-education and an answer to physical limitation.<br/>

                            Following are a few ways in which Pilates helped me in my journey towards rehabilitation:</p>
                        <div className='blo11'>
                            <p className='blo12'>
                                <li>Pilates is good for chronic fatigue – As it is a low impact exercise designed to strengthen key muscle groups without causing full-body fatigue, I could do Pilates because my trainers could customise my exercise routine to a level that my body could tolerate, without too much of exertion. For me, Pilates was a great way to ease into fitness and build strength. Since I’m someone who has always tried to be active, given my physical limitations, it wasn’t very hard to start Pilates.</li>
                                <li>Pilates is all about Mind and Body Connection – Pilates helped me become attuned to my body and taught me how to really control my movement through proper breathing, correct pelvic and spinal alignment. This is how it automatically helped in the reduction of my pain because while performing these exercises breathing played a very important role. Proper breathing not only allows you to execute every movement efficiently, but it also helps in the reduction and management of stress at the same time. When my oxygen levels are good, I feel better and breathing also simultaneously helped in the recovery of my muscles.</li>
                                <li> How Pilates help in improving balance – Many injuries are caused by muscular imbalances within our bodies. Most of us move incorrectly in some way or the other, which puts too much pressure on some muscles and weakens the others, causing an imbalance. Pilates taught me to coordinate the muscles of my arms and legs with my core, which helped me improve my body balance. If one starts building their core muscles through a tailored and customised program, as I did, it can do wonders for one’s body. Doctors had told me I wouldn’t be able to walk, but slowly and gradually when I started strengthening my core muscles, it not only improved my posture but I could walk again.</li>
                                <li> Pilates helps in the release of hormones such as endorphins, which makes one feel more energised. Endorphins are a “feel-good” compound that triggers the mind into a positive place. For me, Pilates is a form of exercise that reduced the feeling of depression and helped with my overall fatigue as well.</li>
                                <li>Pilates is not only limited to a setting, it can be done anywhere, right from the comfort of your home to doing it in an aerobic zone (as long as we have the right trainer guiding us throughout), which is very effective in increasing the levels of oxygen in our bloodstream and as a result, it helps us feel more energised.</li></p>
                        </div>
                        <p className='blo13'>Pilates is used by physiotherapists all over the world, to increase postural awareness and to alter faulty patterns of movement by addressing muscle imbalances. Through Pilates I have noticed greater control in my spinal stability, pelvic stability and mobility, enabling me to improve my posture, inner strength and balance leading to an overall healthy movement, minimising stress to my joints during everyday activities.</p>
                        <p className='blo14'>The writer is the Director of Corefit Plus.</p>
                        <p className='blo15'>Pilates is a systemised form of exercise, which focuses on core strengthening, which includes our abdominal and our back muscles. Pilates is now widely practised as an injury rehabilitation method on a global scale to restore imbalances in injured muscles. By evaluating a person’s state, strength, targets, and range of movement, a pilates personal training approach can be designed to facilitate injury rehabilitation of your muscles. It is a great tool to assist or even enhance a physiotherapy program when someone is recovering from an injury.</p>

                    </div>





                </div>
        </>
    )
}

export default Blog1