import React from "react";
import "./MeetUsAt.css";
import Noida from "./Noida";
import GP from "./GP";
import GK from "./GK";
import NFC from "./NFC";
import GK2 from "./GK2";
const MeetUsAt = () => {
  return (
    <>
      <div className="me1">
        <GK />
        <GP />
        <Noida />
        <NFC />
        <GK2 />
      </div>
    </>
  );
};

export default MeetUsAt;
