import React from "react";
import "./Noida.css";
import { Accordion } from "react-bootstrap";
import ImageGrid from "../Components/Nc.js";

const Noida = () => {
  return (
    <>
      <section id="noida1">
        <div className="noida">
          <div className="noida1">
            <h4>
              Best Pilates Studio in NOIDA
              <hr className="noida11" />
            </h4>
            <div className="noida2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.9283723873764!2d77.36729717522265!3d28.5418725881924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce605f0000001%3A0xb97f5e2c345f9d74!2sCoreFit%20Plus%20Pilates%20Studio%20%7C%20Yoga%20%26%20Pilates%20Workout%20Training!5e0!3m2!1sen!2sin!4v1693989909389!5m2!1sen!2sin"
                title="NoidaMap"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="noida3">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3>Noida</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      {" "}
                      301, 3rd floor Starling Edge, Village Hazipur, Sector 104
                      Noida, Uttar Pradesh-201301.
                    </p>{" "}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="noida23">
              <h4>
                Studio Gallery
                <hr className="noida11" />
              </h4>
              <ImageGrid />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Noida;
