import React from 'react'
import './Wwb.css'
import Img04 from '../Images/28760096ce5a26d503492e774f41caf2.jpg'


const Wwb = () => {
  return (
    <>
      <div className='qw'>
        <p style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>What We <span className='ap2'>Believe</span></p>
      </div>
      <div className='io'>
        <div className='wer'>
          <img src={Img04} alt='xxx' />
        </div>
        <div className='ioo'>
          <div className='vvv'>
            <p className='bb' style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>Mission</p>
            <p className='cc'>At CoreFit Plus, our mission is to redefine fitness as a wellness journey of the mind, body, and spirit. We are committed to cultivating an inclusive, empowering environment that promotes overall wellness beyond the physical realm. We strive to provide a diverse range of fitness experiences –incorporating Pilates and more – that cater to individuals at all fitness levels. We believe that through our integrated approach, we can support our members in achieving not only their physical fitness goals but also in nurturing their emotional and mental well-being.</p>
          </div>
          <div className='vvv'>
            <p className='bb' style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>Vision</p>
            <p className='cc'>Our vision is to be a pioneering force in the fitness industry, transforming the traditional concept of a Pilates studio into an all-encompassing wellness sanctuary. We envision a world where fitness extends beyond the confines of weight loss and physical strength, embracing mental resilience and emotional fitness as integral components of overall health. At CoreFit Plus, we aspire to foster a community where every individual, regardless of their fitness level, can embark on a unique, enriching journey towards comprehensive wellness. We aim to inspire positive change, one mindful movement at a time.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wwb