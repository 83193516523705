import React, { useState } from "react";
import "./ImageGrid.css"; // Import your CSS file
import GKImg1 from "../Images/GK 1 Picture Gallery/1.png";
import GKImg2 from "../Images/WEBP/2.webp";
import GKImg3 from "../Images/WEBP/3.webp";
import GKImg4 from "../Images/WEBP/4.webp";
import GKimg5 from "../Images/WEBP/5.webp";
import GKimg6 from "../Images/kik.webp";
import GKimg7 from "../Images/WEBP/7.webp";
import GKimg8 from "../Images/Greater Kailash Gallery/GK Gallery.webp";
import GKimg9 from "../Images/WEBP/9.webp";
import GKimg10 from "../Images/WEBP/10.webp";

const ImageGridd = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    GKimg10,
    GKImg2,
    GKImg3,
    GKImg4,
    GKimg5,
    GKimg6,
    GKimg7,
    GKimg8,
    GKimg9,
    GKImg1,
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseCarousel = () => {
    setSelectedImage(null);
  };

  const handleNextImage = () => {
    const currentIndex = images.indexOf(selectedImage);
    if (currentIndex < images.length - 1) {
      setSelectedImage(images[currentIndex + 1]);
    } else {
      setSelectedImage(images[0]);
    }
  };
  const handlePrevImage = () => {
    const currentIndex = images.indexOf(selectedImage);
    if (currentIndex > 0) {
      setSelectedImage(images[currentIndex - 1]);
    }
  };
  return (
    <div className="ui1">
      <div className="ui2">
        <div className="ui3">
          {images.slice(0, 5).map((image, index) => (
            <div key={index} className="image-container">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))}
        </div>
        <div className="ui3">
          {images.slice(5).map((image, index) => (
            <div key={index} className="image-container">
              <img
                src={image}
                alt={`Image ${index + 6}`}
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="overl">
          <div className="carousel">
            <button className="close-button" onClick={handleCloseCarousel}>
              <i className="fas fa-times"></i>
            </button>
            <button onClick={handlePrevImage}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <img src={selectedImage} alt="Selected" />
            <button onClick={handleNextImage}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>{" "}
        </div>
      )}
    </div>
  );
};
export default ImageGridd;
