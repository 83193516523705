import React from "react";
import "./PrivacyPolicy.css";
import MainImg from "../Images/privacy policy & terms.webp";
import Navbar from "./Navbar";
import Headroom from "react-headroom";

const RefundPolicy = () => {
  return (
    <>
      <div className="Hero1">
        <img src={MainImg} alt="xxx" className="hero-image" />
        <Headroom>
          <div className="sticky-navbar">
            <Navbar />
          </div>
        </Headroom>
      </div>
      <div className="overlayxy" style={{ backgroundColor: "unset" }}>
        <div className="contentt-container">
          <p className="writte1">Refund & Cancellation Policy</p>
        </div>
      </div>
      <div className="privacypolicy">
        <div className="containerp">
          <div className="privacy-blk">
            <h3 style={{ color: "#d7bd92" }}>Refund & Cancellation Policy</h3>

            <br />
            <h4>Class Booking & Payment:</h4>
            <p>All classes must be booked and paid for in advance.</p>
            <p>
              Your booking is confirmed only after full payment is received.
            </p>
            <p>You can book a class through our app, or at the studio.</p>
            <br />
            <h4>Cancellation Policy</h4>
            <br />
            <h4>For Individual Classes:</h4>
            <p>
              Cancel 4+ hours before class: You will receive a class credit for
              future use.
            </p>
            <p>
              Cancel less than 4 hours before class: The full class fee will be
              charged, and no credit will be issued.
            </p>
            <p>
              No-shows: The full class fee will be charged, and no credit will
              be issued.
            </p>
            <h4>Refund Policy</h4>
            <p>
              All membership fees, class packages, and one-time class payments
              are non-refundable.
            </p>

            <h4>General Terms</h4>
            <p>Policies may change without prior notice.</p>
            <p>
              The management reserves the right to interpret and apply the
              policies as needed.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
