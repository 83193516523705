import React from "react";
import "./ContactUs.css";
import PhoneImg from "../Images/ic_phone.svg";
import EmailImg from "../Images/ic_mail.svg";
import FaqWidget from "./FaqWidget";
import Whatsapp from "../Components/Whatsapp";
import MainImg from "../Images/Website Banner Header  (1).png";
import Navbar from "../Components/Navbar";
import Headroom from "react-headroom";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact us | Corefit Plus Pilates Studio";
  }, []);

  const [formData, setFormData] = useState({
    fname: "",
    lname: " ",
    email: " ",
    phone: " ",
    message: " ",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const { fname, lname, email, phone, message } = formData;
    const mailtoLink = `mailto:abc@gmail.com?subject=Form Submission&body=Name: ${fname}%20${lname}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Corefit Plus Pilates Studio</title>
        <meta
          name="description"
          content="Connect with CorefitPlus Pilates Studio – Your Wellness Starts Here."
        />
      </Helmet>
      <Whatsapp />
      <FaqWidget />
      <div className="Hero1">
        <img src={MainImg} alt="xxx" className="hero-image" />
        <Headroom>
          <div className="sticky-navbar">
            <Navbar />
          </div>
        </Headroom>
      </div>{" "}
      <div className="overlayxy">
        <div className="contentt-container">
          <p>
            {" "}
            <span className="writte1">Contact Us</span>
          </p>
        </div>{" "}
      </div>
      <div className="cun">
        <div className="cun1">
          <h4 style={{ fontFamily: "'Demibolds',sans-serif" }}>
            MEET US AT <hr className="cu9" />
          </h4>

          {/* <h6>Location:</h6> */}

          <div className="cu1">
            <div className="cu2">
              <p className="cu5">GREATER KAILASH I:</p>
              <p>
                S- 15,
                <br /> S Block, Greater Kailash-1,
                <br /> Greater Kailash, New Delhi, Delhi-110048
              </p>
              <p className="cu6">
                <img src={PhoneImg} alt="xxx" />
                +91 99102 24464,
                <br /> +91 11 4752 9468
              </p>
              <p className="cu7">
                <img src={EmailImg} alt="xxx" />
                gkone@corefitplus.com
              </p>
            </div>
            <hr className="cu8" />
            <div className="cu2">
              <p className="cu5">GREEN PARK:</p>
              <p>
                K- 2,
                <br /> Near Greenpark Market,
                <br /> Green Park, New Delhi, Delhi-110016
              </p>
              <p className="cu6">
                <img src={PhoneImg} alt="xxx" /> +91 99710 04464,
                <br /> +91 11 3504 9625
              </p>
              <p className="cu7">
                <img src={EmailImg} alt="xxx" />
                greenpark@corefitplus.com
              </p>
            </div>
            <hr className="cu8" />
            <div className="cu2">
              <p className="cu5">NEW FRIENDS COLONY:</p>
              <p>
                52, 1st Floor, Community Centre,
                <br /> New Friends Colony
                <br /> New Delhi-110025.
              </p>
              <p className="cu6">
                <img src={PhoneImg} alt="xxx" />
                +91 85270 50267,
                <br /> 011 41579183
              </p>
              <p className="cu7">
                <img src={EmailImg} alt="xxx" />
                Nfc@corefitplus.com
              </p>
            </div>
            <hr className="cu8" />

            <div className="cu2">
              <p className="cu5">NOIDA:</p>
              <p>
                301, 3rd floor Starling Edge,
                <br /> Village Hazipur, Sector 104
                <br /> Noida, Uttar Pradesh-201301.
              </p>
              <p className="cu6">
                <img src={PhoneImg} alt="xxx" />
                +91 85952 68566,
                <br /> +91 96677 44326
              </p>
              <p className="cu7">
                <img src={EmailImg} alt="xxx" />
                starling@corefitplus.com
              </p>
            </div>
            <hr className="cu8" />

            <div className="cu2">
              <p className="cu5">GREATER KAILASH II:</p>
              <p>
                M-510, Ground Floor,
                <br /> Greater Kailash 2
                <br /> New Delhi-110048.
              </p>
              <p className="cu6">
                <div style={{ height: "50px" }} />
              </p>
              <p className="cu7">
                <img src={EmailImg} alt="xxx" />
                Gk2@corefitplus.com
              </p>
            </div>
          </div>
        </div>
        <div className="cun2">
          <div className="tycc12">
            <h4
              style={{ fontFamily: "'Demibolds',sans-serif", color: "black" }}
            >
              CONTACT FORM <hr className="cu9" />
            </h4>
            <div className="tycc13">
              <form onSubmit={handleSubmit}>
                <div className="tycc14">
                  <div>
                    <input
                      onChange={handleChange}
                      value={FormData.fname}
                      type="text"
                      name="fname"
                      placeholder="First Name*"
                    />
                    <input
                      onChange={handleChange}
                      value={FormData.lname}
                      type="text"
                      name="lname"
                      placeholder="Last Name*"
                    />
                  </div>
                  <div>
                    <input
                      onChange={handleChange}
                      value={FormData.email}
                      type="text"
                      name="email"
                      placeholder="Email*"
                    />
                    <input
                      onChange={handleChange}
                      value={FormData.phone}
                      type="number"
                      name="phone"
                      placeholder="Phone*"
                    />
                  </div>
                </div>
                <textarea
                  onChange={handleChange}
                  value={FormData.message}
                  type="text"
                  name="message"
                  rows="7"
                  placeholder="Type your comment*"
                ></textarea>
                <br />
                <br />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
