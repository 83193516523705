import React, { useEffect } from "react";
import { useState } from "react";
import "./Navbar.css";
import LinkImg from "../Images/Link - Corefit Plus ⏵ Untitled-design-11-1.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMobile, setisMobile] = useState(false);
  const [razorpayKey, setRazorpayKey] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [amount, setAmount] = useState("");
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isPopupVisible)
      fetch("https://corefitplus-backend.el.r.appspot.com/get-razorpay-key")
        .then((response) => response.json())
        .then((data) => setRazorpayKey(data.key))
        .catch((error) => console.error("Error fetching Razorpay Key:", error));
  }, [isPopupVisible]);

  const openGoogleForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link",
      "_blank"
    );
  };

  const loadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  };

  const handlePayment = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Please enter a valid amount.");
      return;
    }

    loadRazorpay();

    const response = await fetch(
      "https://corefitplus-backend.el.r.appspot.com/create-order",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: amount * 100 }), // Convert to paise
      }
    );

    const order = await response.json();

    const options = {
      key: razorpayKey,
      amount: order.amount,
      currency: "INR",
      name: "Corewellness Pvt. Ltd.",
      description: "Test Transaction",
      order_id: order.id,
      handler: function (response) {
        console.log(response);
      },
      prefill: {
        name: "Core Wellness Pvt. Ltd.",
        email: "info@corefitplus.com",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
    setPopupVisible(false); // Close popup after submission
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar4">
          <div className="navbar2">
            <Link to="/">
              <img src={LinkImg} alt="xxx" />
            </Link>
          </div>
          <div
            className={isMobile ? "navbar9" : "navbar3"}
            onClick={() => setisMobile(false)}
          >
            <li>
              <Link onClick={scrollToTop} className="no-underline" to="/">
                HOME
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                className="no-underline"
                to="/aboutus"
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                className="no-underline"
                to="/Classes"
              >
                CLASSES
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                className="no-underline"
                to="/ourstudio"
              >
                OUR LOCATIONS
              </Link>
            </li>
            <li style={{ maxWidth: "180px" }}>
              <Link
                onClick={scrollToTop}
                className="no-underline"
                to="/PilatesTeachersTraining"
              >
                TEACHER TRAINING CERTIFICATION
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} className="no-underline" to="/Faqs">
                FAQs
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} className="no-underline" to="/blog1">
                BLOG
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                className="no-underline"
                to="/contactus"
              >
                CONTACT US
              </Link>
            </li>
            <button onClick={openGoogleForm}> Book a Trial Now</button>
            <button onClick={() => setPopupVisible(true)}>Pay Now</button>
          </div>
        </div>
        <div className="navbar5">
          <div className="navbar6">
            <Link to="/">
              <img src={LinkImg} alt="xxx" />
            </Link>
          </div>
          <div className="navbar7">
            <button onClick={() => setPopupVisible(true)} className="gaft">
              Pay Now
            </button>
            <button className="hm" onClick={() => setisMobile(!isMobile)}>
              {isMobile ? (
                <i className="fas fa-times"></i>
              ) : (
                <i className="fas fa-bars"></i>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Payment Popup */}
      {isPopupVisible && (
        <div className="overlay" onClick={() => setPopupVisible(false)}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-button"
              onClick={() => setPopupVisible(false)}
            >
              &times;
            </button>
            <h3>Enter Payment Amount</h3>
            <input
              type="number"
              placeholder="Enter amount in INR"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="input-field"
            />
            <button onClick={handlePayment} className="submit-button">
              Proceed to Pay
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
